<template lang="">
  <div>

    <b-card>
      <basetable
        ref="basetable"
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :filter="filter"
        :is-add="false"
        :is-edit="false"
        :is-delete="false"
      >
        <template #cell(is_active)="data">
          <span
            class="badge "
            :class="data.item.is_active?'bg-primary':'bg-warning'"
          >
            {{ data.item.is_active?'Aktif':'Tidak Aktif' }}
          </span>
        </template>
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard
  },
  data() {
    return {
      dataurl:"/user",
      baseroute:"user",
      title:"User",
      fields: [
        // { key: 'username', label: 'Username', sortable: true },
        { key: 'email', label: 'email', sortable: true },
        { key: 'contact.name', label: 'Nama', sortable: true },
        { key: 'role.name', label: 'level', sortable: true },
        { key: 'is_active', label: 'Status', sortable: true }
      ],
      showFilter : true,
      filter :[],
    }
  },
  methods:{
    show(){
      // this.filter['filter[active]']=this.active
      // this.filter['filter[region_id]']=this.region.id
      this.$refs.basetable.fetchData()
    },
    reset(){
      this.selectedCheckedBox = []
      this.active = null
      this.show()
    }
  }
}
</script>
<style lang="">

</style>
